import InputField from "../components/fields/InputField";
import Checkbox from "../components/checkbox";
import Button from "../components/button/index";
import CardItem from "../components/card/CardItem";
import { ReactComponent as Logo } from '../assets/svg/intsamis_logo.svg';
import { MdLogin } from "react-icons/md";

export default function SignIn() {
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
      
      <div className="relative flex flex-col w-1/3">
          <div>
            <img src={'intsamis_logo.png'} className="App-logo" alt="logo" />
            <div className="mt-1 ml-1 h-2.5 font-poppins text-[32px] font-bold uppercase text-brand-600 dark:text-brand-400">
              INTSAMIS <span class="font-medium text-brandSecondary dark:text-brandSecondary">Perch</span>
            </div>
          </div>
      </div>

      {/* Sign in section */}
      <CardItem 
      extra="w-[400px] mt-8"
      icon={<MdLogin/>}
      title={<h4 className="text-3xl font-bold text-navy-700 dark:text-white">Sign In</h4>}>
      
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
      
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
        />

        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
        />

        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a>
        </div>
        <Button extra="w-full">
          Sign In
        </Button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div>
      </div>
      </CardItem>

      

    </div>
  );
}
