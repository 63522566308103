import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import SignIn from './views/SignIn';
import Layout from './Layout';

import Error404 from './Error404';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<SignIn />}/>
          <Route path="login" element={<SignIn />}/>
          <Route path="*" element={<Error404 />}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
