import React from "react";
import Card from "./Card";

const CardItem = (props) => {
  const { extra, icon, title, children, cardMenu, ...rest } = props;
  return (
    <Card extra={`pb-7 p-[20px] ${extra}`}>
      {/* header */}
      <div className="relative flex flex-row justify-between">
        <div className="flex items-center">
          <div className="flex h-9 w-9 items-center justify-center rounded-full bg-lightPrimary dark:bg-navy-700 dark:bg-white/5">
            {icon}
          </div>
          <h4 className="ml-4 text-xl font-bold text-navy-700 dark:text-white">
            {title}
          </h4>
        </div>
        {cardMenu}
      </div>
      {/* content */}
      <div className="h-full w-full">
        {children}
      </div>
    </Card>
  );
};

export default CardItem;
