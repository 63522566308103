import { Outlet } from "react-router-dom";

function Layout(){
    return(

        <div className="App-header">
            <Outlet />
        </div>
    
        
    )
}

export default Layout;