function Button(props) {
  const { variant, extra, children, ...rest } = props;
  return (
    <button className={`rounded-xl bg-gradient-to-br from-brandSecondary to-brandLinear px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-brandLinear/50 ${extra}`}>
      {children}
    </button>
  );
}

export default Button;
